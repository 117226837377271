import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BasicTutorialsComponent } from './basic-tutorials/basic-tutorials.component';
import { IssueAssetsComponent } from './issue-assets/issue-assets.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'issue-assets',
    pathMatch: 'full'
  },
  {
    path: 'basic-tutorials',
    component: BasicTutorialsComponent,
  },
  {
    path: 'issue-assets',
    component: IssueAssetsComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
