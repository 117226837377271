import { Component } from '@angular/core';

@Component({
  selector: 'app-issue-assets',
  templateUrl: './issue-assets.component.html',
  styleUrls: ['./issue-assets.component.css']
})
export class IssueAssetsComponent {

  codePHP = `// This is a PHP example
  <?php
  echo "<h2>PHP is Fun!</h2>";
  echo "Hello world!<br>";
  echo "I'm about to learn PHP!<br>";
  echo "This ", "string ", "was ", "made ", "with multiple parameters.";
  ?>
  }`;

  codeJs = `// This is a JS example
  const test = "Hello";
  console.log(test);
  function myFunction() {
    document.getElementById("demo1").innerHTML = "Test 1!";
    document.getElementById("demo2").innerHTML = "Test 2!";
  }`

  codePy = `// This is a Python example
  num1 = 1.5
  num2 = 6.3
  sum = num1 + num2
  print('The sum of {0} and {1} is {2}'.format(num1, num2, sum))`

}
