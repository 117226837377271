<div>
  <div>
    <h2 class="main-title">Issue an Asset Tutorial</h2>
    <p class="main-description">Sed imperdiet erat sed nisi pulvinar, quis aliquam metus hendrerit. Vestibulum ante
      ipsum primis in faucibus
      orci luctus et ultrices posuere cubilia curae; Sed feugiat, ipsum ac facilisis cursus, nulla massa luctus
      velit, vulputate vulputate elit risus et leo. Morbi quis diam a tortor cursus mollis vitae sit amet urna.
      Fusce non justo malesuada</p>
  </div>

  <div>
    <h2 class="main-title">Prerequisites</h2>
    <p class="main-description">Sed imperdiet erat sed nisi pulvinar, quis aliquam metus hendrerit. Vestibulum ante
      ipsum primis in faucibus
      orci luctus et ultrices posuere cubilia curae; Sed feugiat, ipsum ac facilisis cursus, nulla massa luctus
      velit, vulputate vulputate elit risus et leo. Morbi quis diam a tortor cursus mollis vitae sit amet urna.
      Fusce non justo malesuada</p>
  </div>

  <div>
    <tabset role="tablist">
      <tab heading="Javascript" id="tab1" role="tab">
        <pre><code [highlight]="codeJs" [lineNumbers]="true" ></code></pre>
      </tab>
      <tab heading="PHP" role="tab">
        <pre><code [highlight]="codePHP" [lineNumbers]="true"></code></pre>
      </tab>
      <tab heading="Python" role="tab">
        <pre><code [highlight]="codePy" [lineNumbers]="true"></code></pre>
      </tab>
    </tabset>
  </div>

  <div>
    <h2 class="main-title">Establish trustline</h2>
    <p class="main-description">Sed imperdiet erat sed nisi pulvinar, quis aliquam metus hendrerit. Vestibulum ante
      ipsum primis in faucibus
      orci luctus et ultrices posuere cubilia curae; Sed feugiat, ipsum ac facilisis cursus, nulla massa luctus
      velit, vulputate vulputate elit risus et leo. Morbi quis diam a tortor cursus mollis vitae sit amet urna.
      Fusce non justo malesuada</p>
  </div>
</div>