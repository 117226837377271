import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { TabsModule } from 'ngx-bootstrap/tabs';
import {
  HIGHLIGHT_OPTIONS,
  HighlightModule,
  HighlightOptions
} from 'ngx-highlightjs';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';


import { BasicTutorialsComponent } from './basic-tutorials/basic-tutorials.component';
import { IssueAssetsComponent } from './issue-assets/issue-assets.component';

@NgModule({
  declarations: [
    AppComponent,
    BasicTutorialsComponent,
    IssueAssetsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    TabsModule.forRoot(),
    HighlightModule,
  ],
  providers: [{
    provide: HIGHLIGHT_OPTIONS,
    useValue: <HighlightOptions>{
      coreLibraryLoader: () => import('highlight.js/lib/core'),
      lineNumbersLoader: () => import('ngx-highlightjs/line-numbers'),
      languages: {
        typescript: () => import('highlight.js/lib/languages/typescript'),
        css: () => import('highlight.js/lib/languages/css'),
        xml: () => import('highlight.js/lib/languages/xml')
      },
    }
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
