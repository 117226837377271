<div>
  <div>
    <h2 class="main-title">Basic Tutorial</h2>
    <p class="main-description">Sed imperdiet erat sed nisi pulvinar, quis aliquam metus hendrerit. Vestibulum ante
      ipsum primis in faucibus
      orci luctus et ultrices posuere cubilia curae; Sed feugiat, ipsum ac facilisis cursus, nulla massa luctus
      velit, vulputate vulputate elit risus et leo. Morbi quis diam a tortor cursus mollis vitae sit amet urna.
      Fusce non justo malesuada</p>
  </div>

  <div>
    <h2 class="main-title">Prerequisites</h2>
    <p class="main-description">Sed imperdiet erat sed nisi pulvinar, quis aliquam metus hendrerit. Vestibulum ante
      ipsum primis in faucibus
      orci luctus et ultrices posuere cubilia curae; Sed feugiat, ipsum ac facilisis cursus, nulla massa luctus
      velit, vulputate vulputate elit risus et leo. Morbi quis diam a tortor cursus mollis vitae sit amet urna.
      Fusce non justo malesuada</p>
  </div>

</div>
