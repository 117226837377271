import { Component, Renderer2 } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Damrev Developers API';
  status: boolean = false;
  version = environment.version;

  constructor(private renderer: Renderer2) { }

  toggleMenu() {
    this.status = !this.status;
    if (this.status) {
      this.renderer.addClass(document.body, 'fixed');
    } else {
      this.renderer.removeClass(document.body, 'fixed');
    }
  }
}
