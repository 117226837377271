import { Component } from '@angular/core';

@Component({
  selector: 'app-basic-tutorials',
  templateUrl: './basic-tutorials.component.html',
  styleUrls: ['./basic-tutorials.component.css']
})
export class BasicTutorialsComponent {

}
