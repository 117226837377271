<div class="api-documentation-wrapper d-flex">
  <!-- Sidebar  -->
  <div class="sidebar-wrapper" [ngClass]="{'show' : status}" (click)="toggleMenu()"></div>
  <nav id="sidebar" [ngClass]="{'show' : status}">
    <div class="hamburger-icon hamburger-icon-sidebar" (click)="toggleMenu()"><img
        src="assets/images/hamburger-icon.svg" alt="hamburger icon"></div>
    <div class="sidebar-header d-flex justify-content">
      <img src="assets/images/damrev-logo.svg" alt="Damrev Logo" width="176px" height="52px" class="m-auto">
    </div>
    <div class="sidebar-title">
      Welcome Michel Berg
    </div>
    <ul class="sidebar-list list-unstyled">
      <li>
        <a href="#" (click)="toggleMenu()">
          Fundamentals and Concepts</a>
      </li>
      <li>
        <a routerLink="/basic-tutorials" routerLinkActive="active" (click)="toggleMenu()">
          Basic Tutorials</a>
      </li>
      <li>
        <a routerLink="/issue-assets" routerLinkActive="active" (click)="toggleMenu()">
          Issue Assets</a>
      </li>
      <li>
        <a href="#" (click)="toggleMenu()">
          Anchor Assets</a>
      </li>
      <li>
        <a href="#" (click)="toggleMenu()">
          Build Applications</a>
      </li>
      <li>
        <a href="#" (click)="toggleMenu()">
          Run a Core Node</a>
      </li>
      <li>
        <a href="#" (click)="toggleMenu()">
          Run an API Server</a>
      </li>
      <li>
        <a href="#" (click)="toggleMenu()">
          Access Historical Data</a>
      </li>
      <li>
        <a href="#" (click)="toggleMenu()">
          Use the Stellar Disbursement</a>
      </li>
      <li>
        <a href="#" (click)="toggleMenu()">
          Tools and SDKs</a>
      </li>
      <li>
        <a href="#" (click)="toggleMenu()">
          Encyclopedia</a>
      </li>
      <li>
        <a href="#" (click)="toggleMenu()">
          Glossary</a>
      </li>
      <li>
        <a href="#" (click)="toggleMenu()">
          Soroban Smart Contracts</a>
      </li>
    </ul>
    <div class="version-number">Version {{version}}</div>
  </nav>


  <!-- Page Content  -->
  <div class="page-content w-100">

    <!-- Header -->
    <div class="page-header d-flex justify-content-between">
      <div class="hamburger-icon" (click)="toggleMenu()"><img src="assets/images/hamburger-icon.svg"
          alt="hamburger icon">
      </div>
      <div class="header-title align-self-center">API Documentation</div>
      <div class=" header-list  ml-auto d-flex m-0 align-self-center">
        <span class="align-self-center notification-icon"></span>
        <span class="divider"></span>
        <div class=" ml-auto d-flex m-0 align-self-center user-list">
          <span class="align-self-center name-item">Michel Berg</span>
          <span class="align-self-center image-item"><img src="assets/images/user-img.png" class="mx-3 user-img"
              alt="user Image"></span>
          <span class="align-self-center arrow-item"></span>
        </div>

      </div>
    </div>
    <div class="page-wrapper">
      <div class="page-main">
        <!-- Page  -->
        <router-outlet></router-outlet>
      </div>
    </div>


  </div>

</div>